<template>
  <div class="container mt-5">
    <div class="row mb-4">
      <div class="col-sm-12">
        <span class="sub-title fs-4">G1 - Platform Specifications</span>
        <a
          class="view-link float-end"
          target="_blank"
          href="https://learn.taranawireless.com/r/9nsvynqqk9iy5c05ck9hrot5iesoqzv"
        >
          <font-awesome-icon :icon="['fas', 'download']" /> Download Complete
          Data Sheet
        </a>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped" id="specs_table">
          <tbody>
            <tr
              v-for="(row, index) in specsData"
              :key="index"
              :rowspan="row.data.length"
            >
              <td class="card-text" width="25%">
                {{ row.name }}
                <span class="text-sm" v-if="row.subtext"
                  ><br />{{ row.subtext }}</span
                >
              </td>
              <td width="75%">
                <table width="100%">
                  <tr
                    class="card-text"
                    v-for="(specdetails, index) in row.data"
                  >
                    <td
                      width="50%"
                      class="align-top"
                      v-html="specdetails.specs"
                    ></td>
                    <td
                      width="50%"
                      class="align-top"
                      v-html="specdetails.description"
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12" v-if="legendsList.length">
        <div v-for="(legend, index) in legendsList" :key="index">
          <div class="card-text" v-html="legend.legend_text"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { utils, writeFileXLSX } from "xlsx";

export default {
  props: {
    specsData: {
      type: Array,
    },
    legendsList: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    exportToExcel(type) {
      var table = document.getElementById("specs_table");
      const wb = utils.table_to_book(table);
      writeFileXLSX(wb, "SpecsReport." + type);
    },
  },
};
</script>
