<template>
  <div class="container bg-outline-danger">
    <div class="row py-5">
      <div class="col-sm-12 my-5">
        <div class="card-text text-center mb-5">
          <h1 class="text-danger">
            You don't have permission to access this page.
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>
