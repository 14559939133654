<template>
  <nav
    class="navbar navbar-expand-lg navbar-light mini-header-bg sticky-top"
    v-click-outside="closeMiniNav"
  >
    <div class="container">
      <button
        class="navbar-toggler custom-toggler"
        type="button"
        @click="openMiniNav"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="miniHeaderNavDropdown">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <template v-for="(menu, index) in miniMenusList">
            <li class="nav-item" v-if="menu.active_children.length == 0">
              <a
                :href="menu.link"
                class="nav-link text-white"
                :target="menu.tab_open == 'new' ? '_blank' : ''"
                >{{ menu.title }}</a
              >
            </li>
            <li class="nav-item dropdown pt-1" v-else>
              <a
                class="dropdown-toggle nav-link text-white"
                :href="menu.link"
                :id="'miniMenuLink' + index"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >{{ menu.title }}
              </a>
              <ul
                class="dropdown-menu"
                :aria-labelledby="'miniMenuLink' + index"
              >
                <li v-for="(submenu, index) in menu.active_children">
                  <a
                    :href="submenu.link"
                    class="dropdown-item"
                    :target="submenu.tab_open == 'new' ? '_blank' : ''"
                    >{{ submenu.title }}</a
                  >
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </nav>
  <nav
    class="navbar navbar-expand-lg navbar-light header-bg sticky-top main-header-top"
    v-click-outside="closeNav"
  >
    <div class="container">
      <router-link to="/home" class="navbar-brand me-0">
        <img
          src="../../assets/images/logo-white.png"
          alt=""
          height="30"
          class="d-inline-block align-text-top"
        />
        <div class="private">Resources</div>
      </router-link>

      <button
        class="navbar-toggler custom-toggler"
        type="button"
        @click="openNav"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="headerNavDropdown">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <template v-for="(menu, index) in mainMenusList">
            <template v-if="menu.active_children.length == 0">
              <li
                class="nav-item"
                v-if="
                  menu.link != '/supply-chain' ||
                  checkPermission('supply-chain')
                "
              >
                <a
                  :href="menu.link"
                  class="nav-link text-white"
                  :target="menu.tab_open == 'new' ? '_blank' : ''"
                  v-if="externalLink(menu.link)"
                >
                  {{ menu.title }}
                  <img
                    src="../../assets/images/external-link.png"
                    alt=""
                    class="d-inline-block"
                  />
                </a>
                <router-link
                  v-else
                  :to="menu.link"
                  class="nav-link text-white"
                  :target="menu.tab_open == 'new' ? '_blank' : ''"
                  @click="storeMenuLink(menu.link)"
                >
                  {{ menu.title }}
                </router-link>
              </li>
            </template>
            <template v-else>
              <li class="nav-item dropdown pt-1">
                <a
                  class="dropdown-toggle nav-link text-white"
                  :href="menu.link"
                  :id="'mainMenuLink' + index"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ menu.title }}
                </a>
                <ul
                  class="dropdown-menu"
                  :aria-labelledby="'mainMenuLink' + index"
                >
                  <li
                    v-for="(submenu, subIndex) in menu.active_children"
                    :key="subIndex"
                  >
                    <a
                      :href="submenu.link"
                      class="dropdown-item"
                      :class="{ active: submenu.link == '/' + currentPage }"
                      :target="submenu.tab_open == 'new' ? '_blank' : ''"
                    >
                      {{ submenu.title }}
                    </a>
                  </li>
                </ul>
              </li>
            </template>
          </template>
          <li class="nav-item dropdown pt-1" id="avatar" v-if="currentUser">
            <a
              class="dropdown-toggle"
              href="#"
              id="profileMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <ProfileAvatar
                :username="currentUser.user.username"
                size="s"
              ></ProfileAvatar>
            </a>
            <ul class="dropdown-menu" aria-labelledby="profileMenuLink">
              <li>
                <router-link
                  to="/account"
                  class="dropdown-item"
                  :class="{ active: currentPage == 'account' }"
                  @click="storeMenuLink('/account')"
                  ><i class="fa fa-cog" aria-hidden="true"></i> Manage
                  Account</router-link
                >
              </li>
              <li>
                <router-link @click="handleLogout" class="dropdown-item" to="#"
                  ><i class="fas fa-sign-out-alt" aria-hidden="true"></i> Log
                  Out</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item buttons" v-if="!currentUser">
            <router-link
              :to="{ path: '/register' }"
              tag="button"
              class="btn btn-outline-light rounded-pill"
              >Sign Up</router-link
            >
          </li>
          <li class="nav-item buttons" v-if="!currentUser">
            <router-link
              :to="{ path: '/login' }"
              tag="button"
              class="btn btn-outline-light rounded-pill"
              >Login</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div
    id="tarana-mini-side-bar"
    class="sidebar"
    :class="taranaMiniSidebar ? 'd-block' : 'd-none'"
  >
    <a href="javascript:void(0)" class="closebtn" @click="closeMiniNav"
      ><i class="fa fa-times" aria-hidden="true"></i>
    </a>
    <ul class="navbar-nav ms-auto mt-3 mb-lg-0">
      <template v-for="(menu, index) in miniMenusList">
        <li class="nav-item" v-if="menu.active_children.length == 0">
          <a
            :href="menu.link"
            class="nav-link text-white"
            :target="menu.tab_open == 'new' ? '_blank' : ''"
            >{{ menu.title }}</a
          >
        </li>
        <li class="nav-item dropdown" v-else>
          <a
            class="dropdown-toggle"
            :href="menu.link"
            :id="'miniMenuLink' + index"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ menu.title }}
          </a>
          <ul class="dropdown-menu" :aria-labelledby="'miniMenuLink' + index">
            <li v-for="(submenu, index) in menu.active_children">
              <a
                :href="submenu.link"
                class="px-2 nav-link text-white"
                :target="submenu.tab_open == 'new' ? '_blank' : ''"
                >{{ submenu.title }}</a
              >
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
  <div
    id="tarana-side-bar"
    class="sidebar"
    :class="taranaSidebar ? 'd-block' : 'd-none'"
  >
    <a href="javascript:void(0)" class="closebtn" @click="closeNav"
      ><i class="fa fa-times" aria-hidden="true"></i>
    </a>
    <ul class="navbar-nav ms-auto mt-3 mb-lg-0">
      <template v-for="(menu, index) in mainMenusList">
        <template v-if="menu.active_children.length == 0">
          <li
            class="nav-item"
            v-if="
              menu.link != '/supply-chain' || checkPermission('supply-chain')
            "
          >
            <a
              :href="menu.link"
              class="nav-link text-white"
              :target="menu.tab_open == 'new' ? '_blank' : ''"
              v-if="externalLink(menu.link)"
            >
              {{ menu.title }}
              <img
                src="../../assets/images/external-link.png"
                alt=""
                class="d-inline-block"
              />
            </a>
            <router-link
              v-else
              :to="menu.link"
              class="nav-link text-white"
              :target="menu.tab_open == 'new' ? '_blank' : ''"
              @click="storeMenuLink(menu.link)"
            >
              {{ menu.title }}
            </router-link>
          </li>
        </template>
        <template v-else>
          <li class="nav-item dropdown">
            <a
              class="dropdown-toggle"
              :href="menu.link"
              :id="'mainMenuLink' + index"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ menu.title }}
            </a>
            <ul class="dropdown-menu" :aria-labelledby="'mainMenuLink' + index">
              <li v-for="(submenu, index) in menu.active_children">
                <a
                  :href="submenu.link"
                  class="px-2 nav-link text-white"
                  :target="submenu.tab_open == 'new' ? '_blank' : ''"
                  >{{ submenu.title }}</a
                >
              </li>
            </ul>
          </li>
        </template>
      </template>
      <li class="nav-item dropdown" v-if="currentUser">
        <a
          class="dropdown-toggle"
          href="#"
          id="profileMenuLink"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{
            currentUser.user.username == "admin"
              ? "Account"
              : ucwords(currentUser.user.username)
          }}
        </a>
        <ul class="dropdown-menu" aria-labelledby="profileMenuLink">
          <li>
            <router-link
              to="/account"
              class="dropdown-item"
              @click="storeMenuLink('/account')"
              ><i class="fa fa-cog" aria-hidden="true"></i> Manage
              Account</router-link
            >
          </li>
          <li>
            <router-link @click="handleLogout" class="dropdown-item" to="#"
              ><i class="fas fa-sign-out-alt" aria-hidden="true"></i> Log
              Out</router-link
            >
          </li>
        </ul>
      </li>
      <li class="nav-item buttons" v-if="!currentUser">
        <router-link
          :to="{ path: '/register' }"
          tag="button"
          class="btn btn-outline-light rounded-pill"
          >Sign Up</router-link
        >
      </li>
      <li class="nav-item buttons" v-if="!currentUser">
        <router-link
          :to="{ path: '/login' }"
          tag="button"
          class="btn btn-outline-light rounded-pill"
          >Login</router-link
        >
      </li>
    </ul>
  </div>
</template>
<script>
import ProfileAvatar from "vue-profile-avatar";
import { hasPermission } from "@/router-guard";
export default {
  components: {
    ProfileAvatar,
  },
  data() {
    return {
      mainMenusList: [],
      miniMenusList: [],
      taranaSidebar: false,
      taranaMiniSidebar: false,
      showOnboarding: process.env.VUE_APP_CUSTOMER_ONBOARDING,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentPage() {
      return this.$route.path.replace("/", "");
    },
  },
  methods: {
    getMainMenus() {
      this.$axios.get("main-menus", {}).then(
        function (response) {
          this.mainMenusList = response.data;
        }.bind(this)
      );
    },
    getMiniMenus() {
      this.$axios.get("mini-menus", {}).then(
        function (response) {
          this.miniMenusList = response.data;
        }.bind(this)
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout", this.currentUser).then(
        () => {
          const lastVisitedURL = localStorage.getItem("lastVisitedURL") ?? "/";
          localStorage.clear();
          location.href = "/home";
          localStorage.setItem("lastVisitedURL", lastVisitedURL);
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    externalLink(url) {
      try {
        new URL(url);
        return true;
      } catch (_) {
        return false;
      }
    },
    storeMenuLink(url) {
      localStorage.setItem("lastVisitedURL", url);
    },
    checkPermission(pageName) {
      pageName = pageName.toLowerCase().replace(/\s+/g, "-");
      let urlPermission = hasPermission(pageName);
      return urlPermission;
    },
    ucwords(str) {
      return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($letter) {
        return $letter.toUpperCase();
      });
    },
    openMiniNav() {
      this.taranaMiniSidebar = true;
    },
    closeMiniNav() {
      this.taranaMiniSidebar = false;
    },
    openNav() {
      this.taranaSidebar = true;
    },
    closeNav() {
      this.taranaSidebar = false;
    },
    closeAllMenus() {
      this.closeMiniNav();
      this.closeNav();
    },
  },
  created() {
    this.getMainMenus();
    this.getMiniMenus();
    // Close sidebars on route change
    this.$router.beforeEach((to, from, next) => {
      this.closeAllMenus();
      next();
    });
    window.addEventListener("resize", this.closeAllMenus);
  },
  destroyed() {
    window.removeEventListener("resize", this.closeAllMenus);
  },
};
</script>
