<template>
  <section class="h-100 pt-3">
    <div class="container h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-xl-10">
          <div class="rounded-3 text-black">
            <div class="row g-0 justify-content-center">
              <div class="col-lg-12">
                <div class="p-md-5 mx-md-4">
                  <div class="text-center">
                    <img
                      src="../assets/images/logo-black.png"
                      style="width: 185px"
                      alt="logo"
                    />
                  </div>
                  <div class="mt-5">
                    <div
                      class="form-outline my-4"
                      v-if="!successful && message"
                    >
                      <div
                        class="alert alert-dissmissable alert-danger"
                        role="alert"
                      >
                        {{ message }}
                      </div>
                    </div>
                    <div v-if="!successful">
                      <div
                        class="row d-flex justify-content-center align-items-center"
                      >
                        <div class="row p-0 justify-content-center">
                          <div class="col-lg-6">
                            <p class="sub-title mb-0">Profile information</p>
                            <p class="text-muted">
                              Let's start with your work e-mail, which will be
                              your username and login:
                            </p>
                            <div>
                              <div class="form-group">
                                <label for="email">Email address</label>
                                <Field
                                  type="email"
                                  name="email"
                                  class="input-custom mb-0"
                                  placeholder="Work Email Address *"
                                  v-model="email"
                                  @input="validateEmail()"
                                />
                                <span
                                  role="alert"
                                  class="error-feedback"
                                  v-if="invalid_email"
                                  >{{ email_error }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Form
                        ref="registerForm"
                        @submit="handleRegister"
                        :validation-schema="validationSchema"
                      >
                        <div class="row mb-3">
                          <div
                            class="col-md-12 card-text"
                            v-if="show_email_response == true"
                          >
                            <p>
                              This e-mail address is already on our list. If
                              it's yours, please try using it to
                              <router-link :to="{ path: '/login' }" tag="button"
                                >log in</router-link
                              >. Otherwise, you may want to contact your team's
                              e-mail account administrator.
                            </p>
                          </div>
                          <div
                            class="col-md-12 card-text text-indigo"
                            v-else
                            v-html="email_response"
                          ></div>
                        </div>
                        <div
                          class="row mb-3"
                          v-show="
                            !not_allowed && !['duplicate'].includes(user_case)
                          "
                        >
                          <div
                            class="col-md-12 py-3"
                            v-show="
                              !['tarana', 'member', 'tbd'].includes(user_case)
                            "
                          >
                            <div
                              class="form-check"
                              v-for="(rb, index) in newCategory.options"
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="category"
                                v-model="category"
                                :id="'category_' + index"
                                @click="takeNewCategoryAction(index)"
                                :value="index"
                              />
                              <label
                                class="form-check-label"
                                v-html="rb.option"
                              ></label>
                            </div>
                            <div
                              class="col-md-12 mt-3"
                              v-show="!newCategory.no_selection"
                            >
                              <p
                                v-html="post_cat_msg"
                                class="card-text text-indigo"
                              ></p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="row mb-3"
                          v-show="
                            !['duplicate', 'tbd', 'unknown'].includes(user_case)
                          "
                        >
                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label for="password">Password</label>
                              <Field
                                type="password"
                                name="password"
                                class="input-custom mb-0"
                                placeholder="Password *"
                                value=""
                              />
                              <ErrorMessage
                                name="password"
                                class="error-feedback"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label for="password_confirmation"
                                >Confirm Password</label
                              >
                              <Field
                                type="password"
                                name="password_confirmation"
                                class="input-custom mb-0"
                                placeholder="Confirm Password *"
                                value=""
                              />
                              <ErrorMessage
                                name="password_confirmation"
                                class="error-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="row mb-3"
                          v-show="
                            !['tarana', 'tbd', 'unknown', 'duplicate'].includes(
                              user_case
                            )
                          "
                        >
                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label for="firstname">First Name</label>
                              <Field
                                type="text"
                                class="input-custom mb-0"
                                placeholder="First Name *"
                                value=""
                                name="firstname"
                              />
                              <ErrorMessage
                                name="firstname"
                                class="error-feedback"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label for="lastname">Last Name</label>
                              <Field
                                type="text"
                                class="input-custom mb-0"
                                placeholder="Last Name *"
                                value=""
                                name="lastname"
                              />
                              <ErrorMessage
                                name="lastname"
                                class="error-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="row mb-3"
                          v-show="
                            !['tarana', 'tbd', 'unknown', 'duplicate'].includes(
                              user_case
                            )
                          "
                        >
                          <p class="sub-title">Organization information</p>
                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label for="title">Title</label>
                              <Field
                                type="text"
                                name="title"
                                class="input-custom mb-0"
                                placeholder="Title"
                                value=""
                              />
                              <ErrorMessage
                                name="title"
                                class="error-feedback"
                              />
                            </div>
                          </div>
                          <div
                            class="col-md-6 mb-3"
                            v-show="
                              [
                                'u_prospect',
                                'u_customer',
                                'u_custpart',
                              ].includes(user_case)
                            "
                          >
                            <div class="form-group">
                              <label for="organization">Organization</label>
                              <Field
                                type="text"
                                name="organization"
                                class="input-custom mb-0"
                                placeholder="Organization *"
                                value=""
                              />
                              <ErrorMessage
                                name="organization"
                                class="error-feedback"
                              />
                            </div>
                          </div>
                          <div
                            class="col-md-6 mb-3"
                            v-show="
                              ['member', 'u_customer', 'u_custpart'].includes(
                                user_case
                              )
                            "
                          >
                            <div class="form-group">
                              <label for="city">City</label>
                              <Field
                                type="text"
                                name="city"
                                class="input-custom mb-0"
                                placeholder="City *"
                                value=""
                              />
                              <ErrorMessage
                                name="city"
                                class="error-feedback"
                              />
                            </div>
                          </div>
                          <div
                            class="col-md-6 mb-3"
                            v-show="
                              ['member', 'u_customer', 'u_custpart'].includes(
                                user_case
                              )
                            "
                          >
                            <div class="form-group">
                              <label for="country">Country</label>
                              <Field
                                type="text"
                                name="country"
                                class="input-custom mb-0"
                                placeholder="Country *"
                                value=""
                              />
                              <ErrorMessage
                                name="country"
                                class="error-feedback"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="row mb-3"
                          v-show="user_case == 'u_custpart'"
                        >
                          <div class="col-md-12 card-text mb-3">
                            To help us connect the dots on our end, please
                            provide one customer's company name that you are
                            associated with.
                          </div>
                          <!-- <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label for="serial">Serial</label>
                              <Field
                                type="text"
                                name="serial"
                                class="input-custom mb-0"
                                placeholder="Serial*"
                                value=""
                                maxlength="15"
                              />
                              <p class="small-text mb-0">
                                Make sure it's 15 digits
                              </p>
                              <ErrorMessage
                                name="serial"
                                class="error-feedback"
                              />
                            </div>
                          </div> -->
                          <div class="col-md-6 mb-3">
                            <div class="form-group">
                              <label for="aff">Customer</label>
                              <Field
                                type="text"
                                name="aff"
                                class="input-custom mb-0"
                                placeholder="Customer *"
                                value=""
                              />
                              <ErrorMessage name="aff" class="error-feedback" />
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-12"
                          v-if="
                            email_response &&
                            !not_allowed &&
                            !['duplicate', 'unknown'].includes(user_case)
                          "
                        >
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <input
                              type="submit"
                              class="button-indigo w-50"
                              value="Register"
                            />
                          </div>
                          <p class="mt-4 text-center">
                            Already registered?
                            <router-link :to="{ path: '/login' }" tag="button"
                              >Login here</router-link
                            >
                          </p>
                        </div>
                      </Form>
                    </div>
                    <div class="my-3 text-success text-center" v-else>
                      <h1>
                        <span v-if="user_case != 'tarana'">{{ message }}</span>
                        <span v-else
                          >Thank you for registering. Please
                          <a href="/login">login</a> to access the portal.</span
                        >
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      successful: false,
      loading: false,
      email: "",
      entity_id: 0,
      organization: "",
      email_response: "",
      post_cat_msg: "",
      show_email_response: false,
      not_allowed: false,
      user_case: "tbd",
      category: "",
      invalid_email: false,
      email_error: "",
      newCategory: {
        options: [
          {
            option:
              "interested in more detail about how G1 could help my cause",
            cat: "prospect",
            selected: false,
          },
          {
            option: "a new G1 customer",
            cat: "customer",
            selected: false,
          },
          {
            option:
              "Distributor or Partner working with G1 equipment on behalf of a Tarana customer and need access to resources and/or support",
            cat: "customer_partner",
            selected: false,
          },
        ],
        selection: "",
        no_selection: true,
      },
      message: "",
      validationSchema: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },
  created() {
    this.buildValidationSchema();
  },
  methods: {
    takeNewCategoryAction(i) {
      this.newCategory.no_selection = false;
      let msg = "Great. ";
      switch (i) {
        case 0: // browsing prospect
          this.user_case = "u_prospect";
          msg +=
            "We certainly welcome your interest. Please choose a password and share your name, title, and company name, and you'll be good to go after validating your e-mail.";
          break;
        case 1: // current customer, but domain not registered
          this.user_case = "u_customer";
          msg +=
            "Welcome to Tarana Resources. Please share with us the info below, and you'll be good to go after validating your e-mail:";
          break;
        case 2: // new partner affiliated with current customer
          this.user_case = "u_custpart";
          msg +=
            "Welcome to the G1 team. Please share with us the info below, and you'll be good to go after validating your e-mail:";
      }
      this.post_cat_msg = msg;
      this.$refs.registerForm.resetForm();
      this.buildValidationSchema();
    },
    handleEmailEdit() {
      this.category = "";
      this.show_email_response = false;
      this.not_allowed = false;
      this.post_cat_msg = "";
      this.email_response = "";
      let msg = "";
      this.user_case = "tbd";
      this.$axios.post("check-user-email", { email: this.email }).then(
        function (response) {
          if (response.data.entityData) {
            this.organization = response.data.entityData.organization;
            this.entity_id = response.data.entityData.entity_id;
            let caseinfo = response.data.entityData.case;
            switch (caseinfo) {
              case "duplicate":
                this.show_email_response = true;
                this.user_case = "duplicate";
                break;
              case "Tarana":
                msg =
                  "Thanks. For Taranans, we just need a password and you're good to go.";
                this.user_case = "tarana";
                break;
              case "member":
                msg =
                  "Thanks. We're pleased to welcome a new member from the " +
                  this.organization +
                  " team. We need you to set a password and provide a little more info:";
                this.user_case = "member";
                break;
              case "new":
                msg =
                  "Thanks. Looks like we don't have your company or organization on our list, based on your e-mail domain. Please choose the description below that fits you best:";
                this.user_case = "unknown";
                break;
              case "disallowed":
                let no_type = response.data.entityData.no_type;
                this.not_allowed = true;
                msg =
                  no_type == "free"
                    ? "We do prefer that you use your work e-mail address. Thank you."
                    : 'Thanks for your interest. Our Resource site is reserved for interested professionals, customers, and partners. Please feel free to browse our <a href="https://www.taranawireless.com">public site</a>.';
                break;
            }
          }
          this.email_response = "<hr />" + msg;
          this.$refs.registerForm.resetForm();
          this.buildValidationSchema();
        }.bind(this)
      );
    },
    validateEmail() {
      const schema = yup.object().shape({
        email: yup
          .string()
          .required("Email is required!")
          .matches(
            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Email is invalid!"
          )
          .max(50, "Must be maximum 50 characters!"),
      });

      schema
        .validate({ email: this.email })
        .then(() => {
          // Email is valid
          this.invalid_email = false;
          this.email_error = "";
          this.handleEmailEdit();
        })
        .catch((error) => {
          // Email is invalid
          this.invalid_email = true;
          this.email_error = error.errors[0];
          this.show_email_response = false;
          this.not_allowed = false;
          this.post_cat_msg = "";
          this.email_response = "";
          this.user_case = "tbd";
        });
    },
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      user.user_case = this.user_case;
      if (this.entity_id != 0) {
        user.entity_id = this.entity_id;
      }
      if (this.email) {
        user.email = this.email;
      }
      if (this.organization) {
        user.organization = this.organization;
      }

      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          loader.hide();
          this.message =
            "Thank you for registering. Please check your inbox and/or spam folder for a confirmation email. Once your email is confirmed you will be able to login and access the portal.";
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          loader.hide();
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
    buildValidationSchema() {
      const commonSchema = {
        password: yup
          .string()
          .required("Password is required!")
          .min(8, "Must be at least 8 characters!")
          .test(
            "isValidPass",
            "The password requires an uppercase, lowercase, number and special character",
            (value, context) => {
              const hasUpperCase = /[A-Z]/.test(value);
              const hasNumber = /[0-9]/.test(value);
              const hasLowerCase = /[a-z]/.test(value);
              const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(
                value
              );
              let validConditions = 0;
              const numberOfMustBeValidConditions = 3;
              const conditions = [
                hasUpperCase,
                hasLowerCase,
                hasNumber,
                hasSymbole,
              ];
              conditions.forEach((condition) =>
                condition ? validConditions++ : null
              );
              if (validConditions >= numberOfMustBeValidConditions) {
                return true;
              }
              return false;
            }
          ),
        password_confirmation: yup
          .string()
          .required("Confirm Password is required!")
          .oneOf([yup.ref("password")], "Passwords must match!"),
      };

      const userSchema = {
        firstname: yup.string().required("First name is required!"),
        lastname: yup.string().required("Last name is required!"),
      };

      const companySchema = {
        organization: yup.string().required("Organization is required!"),
      };

      const locationSchema = {
        city: yup.string().required("City is required!"),
        country: yup.string().required("Country is required!"),
      };

      /* const customerSchema = {
        serial: yup
          .string()
          .required("Serial number is required!")
          .length(15, "Serial number must be 15 digits!"),
        aff: yup.string().required("Customer name is required!"),
      }; */

      const customerSchema = {
        aff: yup.string().required("Customer name is required!"),
      };

      let schema = {
        ...commonSchema,
      };
      if (this.user_case == "member") {
        schema = {
          ...commonSchema,
          ...userSchema,
          ...locationSchema,
        };
      }
      if (this.user_case == "u_prospect") {
        schema = {
          ...commonSchema,
          ...userSchema,
          ...companySchema,
        };
      }
      if (this.user_case == "u_customer") {
        schema = {
          ...commonSchema,
          ...userSchema,
          ...companySchema,
          ...locationSchema,
        };
      }
      if (this.user_case == "u_custpart") {
        schema = {
          ...commonSchema,
          ...userSchema,
          ...companySchema,
          ...locationSchema,
          ...customerSchema,
        };
      }

      // Combine the schemas into a single schema
      this.validationSchema = yup.object().shape(schema);
    },
  },
};
</script>
