<template>
  <div class="container">
    <div class="bg-grey" v-for="(platform, index) in platformData">
      <div class="row">
        <div class="col-sm-12 sub-title mb-3">
          {{ platform.sub_title }}
        </div>
      </div>
      <div v-html="platform.description"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    platformData: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>
