<template>
  <section class="h-100 pt-3">
    <div class="container h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-xl-5">
          <div class="row g-0">
            <div class="col-lg-12">
              <div class="p-md-5 mx-md-4">
                <div class="text-center">
                  <img
                    src="../assets/images/logo-black.png"
                    style="width: 185px"
                    alt="logo"
                  />
                </div>
                <div v-if="!success">
                  <p class="text-center">
                    Please enter your email id for reset password
                  </p>
                  <Form
                    @submit="handleForgotPassword"
                    :validation-schema="schema"
                  >
                    <div class="form-outline my-4">
                      <div
                        class="alert alert-danger alert-dissmissable"
                        role="alert"
                        v-if="message"
                      >
                        {{ message }}
                      </div>
                    </div>

                    <div class="form-group mb-4">
                      <label for="email">Email Address</label>
                      <Field
                        name="email"
                        type="text"
                        class="input-custom mb-0"
                        placeholder="Email Address"
                      />
                      <ErrorMessage name="email" class="error-feedback" />
                    </div>

                    <div class="text-center pt-1 pb-1">
                      <button class="save-btn" type="submit">
                        Forgot Password</button
                      ><br />
                      <router-link to="/login" class="text-indigo"
                        >Login</router-link
                      >
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-center pb-4"
                    >
                      <p class="mb-0 me-2">Don't have an account?</p>
                      <router-link
                        :to="{ path: '/register' }"
                        class="text-indigo"
                        >Register here</router-link
                      >
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8 mb-2" v-if="success">
          <div class="mb-5 text-success text-center">
            <h1>
              Successfully send password reset link to your email. Please check
              your inbox and/or spam folder for the password reset link. To
              reset your password, please click on the reset button provided
              within the email.
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().required("Email address is required!"),
});

export default {
  name: "Forgot Password",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      message: "",
      schema,
      success: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },
  methods: {
    handleForgotPassword(user) {
      this.loading = true;
      this.success = false;
      this.$store.dispatch("auth/forgot", user).then(
        () => {
          this.success = true;
        },
        (error) => {
          this.loading = false;
          this.success = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
