<template>
  <div class="background-img">
    <span class="mask bg-gradient-indigo"></span>
    <div class="centered-text">
      <div class="main-heading">
        Find everything you need to get the most out of your G1 network.
      </div>
      <div class="sub-heading">
        To start, learn how to set up your Tarana network below
      </div>
    </div>
  </div>

  <div class="container d-flex flex-column home">
    <div class="row">
      <div class="col-sm-4 mb-3" v-if="showOnboarding == 'true'">
        <router-link to="/onboarding/cto" class="text-dark">
          <div class="card card-bg">
            <div class="card-body">
              <h5 class="card-title">
                <img
                  src="../assets/images/icons/onboarding.png"
                  alt="Customer Onboarding"
                  class="d-inline-block align-text-top pad-right"
                />
                Onboarding
              </h5>
              <p class="card-text">
                Everything you need to know to get started with your G1 network.
              </p>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-sm-4 mb-3">
        <router-link :to="{ path: '/platforms/specs' }" class="text-dark">
          <div class="card card-bg">
            <div class="card-body">
              <h5 class="card-title">
                <img
                  src="../assets/images/icons/specs.png"
                  alt="Specifications"
                  class="d-inline-block align-text-top pad-right"
                />
                Specs
              </h5>
              <p class="card-text">
                Technical product specifications of radio attributes, networking
                options, OA&M, physical configuration, and operational
                parameters
              </p>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-sm-4 mb-3">
        <a href="/platforms/network-design#technology" class="text-dark">
          <div class="card card-bg">
            <div class="card-body">
              <h5 class="card-title">
                <img
                  src="../assets/images/icons/technology.png"
                  alt="Technology"
                  class="d-inline-block align-text-top pad-right"
                />
                Technology
              </h5>
              <p class="card-text">
                Learn about Tarana's technology impact on performance and how it
                solves the broband problem.
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="col-sm-4 mb-3">
        <a href="/platforms/network-design#performance" class="text-dark">
          <div class="card card-bg">
            <div class="card-body">
              <h5 class="card-title">
                <img
                  src="../assets/images/icons/performance.png"
                  alt="Performance"
                  class="d-inline-block align-text-top pad-right"
                />
                Performance
              </h5>
              <p class="card-text">
                A profile of expected G1 link performance as a function of the
                most critical elements of their configuration and environment.
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="col-sm-4 mb-3">
        <router-link
          :to="{ path: '/platforms/network-design' }"
          class="text-dark"
        >
          <div class="card card-bg">
            <div class="card-body">
              <h5 class="card-title">
                <img
                  src="../assets/images/icons/nwdesign.png"
                  alt="Network Design"
                  class="d-inline-block align-text-top pad-right"
                />
                Network Design
              </h5>
              <p class="card-text">
                A tour through the essential elements of G1 networks.
              </p>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-sm-4 mb-3">
        <a href="/platforms/network-design#planning" class="text-dark">
          <div class="card card-bg">
            <div class="card-body">
              <h5 class="card-title">
                <img
                  src="../assets/images/icons/nwplanning.png"
                  alt="Network Planning"
                  class="d-inline-block align-text-top pad-right"
                />
                Network Planning
              </h5>
              <p class="card-text">
                Recommendations for radio network planning approaches with G1.
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row mt-4 home" v-if="currentUser && resourceList.length > 0">
      <div class="col-sm-12 heading">
        Download resources
        <router-link
          to="/resources"
          class="resource-link float-end d-none d-sm-block"
          >View all resources <i class="fa fa-arrow-right arrow"></i
        ></router-link>
        <hr />
      </div>
      <div class="col-sm-12" v-if="resourceList.length > 0">
        <swiper
          ref="mySwiper"
          :slides-per-view="4"
          :autoplay="autoplay"
          :space-between="20"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          :height="150"
          :breakpoints="breakpoints"
        >
          <swiper-slide v-for="(resource, index) in resourceList" :key="index">
            <div class="col-sm-12">
              <div class="card swiper-card">
                <div class="card-body">
                  <h5 class="card-title">
                    {{
                      resource.name.length > 25
                        ? resource.name.slice(0, 25) + "..."
                        : resource.name
                    }}
                  </h5>
                  <p
                    class="card-text"
                    v-html="formatDescription(resource.description)"
                  ></p>
                  <div class="card-pdf">
                    <span class="pdf-text" v-if="resource.file_name"
                      >{{ getFileType(resource.file_name) }} |
                      {{
                        resource.file_size / (1024 * 1024) < 1
                          ? "<1"
                          : (resource.file_size / (1024 * 1024)).toFixed()
                      }}
                      MB</span
                    >
                  </div>
                  <div class="card-download" v-if="resource.file_name">
                    <a
                      :href="`${getAPIUrl}/storage/${resource.file_path}`"
                      @click="updateActions(resource)"
                      class="pdf-text"
                      target="_blank"
                      ><i class="fa fa-download"></i
                    ></a>
                  </div>
                  <div class="card-download" v-if="resource.resource_link">
                    <a
                      :href="resource.resource_link"
                      @click="updateActions(resource)"
                      class="pdf-text"
                      target="_blank"
                      ><i class="fa fa-eye"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="col-sm-12 text-center d-block d-sm-none mt-4">
        <router-link to="/resources" class="resource-link"
          >View all resources <i class="fa fa-arrow-right"></i
        ></router-link>
      </div>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      resourceList: [],
      resourcePermissions: [],
      categories: [],
      subcategories: [],
      isSmallScreen: false,
      showOnboarding: process.env.VUE_APP_CUSTOMER_ONBOARDING,
      breakpoints: {
        280: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        990: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
    };
  },
  computed: {
    getAPIUrl() {
      const base = this.$axios.defaults.baseURL;
      return base.split("/api")[0];
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  setup() {
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
      autoplay: {
        delay: 4000,
        disableOnInteraction: true,
      },
    };
  },
  mounted() {
    if (this.currentUser) {
      this.getPermissions();
      this.getResources();
    }
    if (this.currentUser) {
      this.updateActions("home");
    }
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    this.isSmallScreen = mediaQuery.matches;
    mediaQuery.addEventListener("change", this.handleScreenSizeChange);
  },
  beforeDestroy() {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    mediaQuery.removeEventListener("change", this.handleScreenSizeChange);
  },
  methods: {
    handleScreenSizeChange(event) {
      this.isSmallScreen = event.matches;
    },
    getFileType(fileName) {
      let ext = "";
      if (fileName != "") {
        const extension = fileName.split(".").pop();
        ext = extension.toUpperCase();
      }
      return ext;
    },
    updateActions(resource) {
      let pagelink = "/home";
      let actionJson = {
        sessionId: this.currentUser.sessionId,
        pagelink: pagelink,
      };
      if (resource != "home") {
        if (resource.resource_link) {
          actionJson = {
            sessionId: this.currentUser.sessionId,
            pagelink: resource.resource_link,
          };
        } else {
          const paddedNumber = resource.id.toString().padStart(4, "0");
          pagelink = `lib${paddedNumber}: ${resource.name}`;
          actionJson = {
            sessionId: this.currentUser.sessionId,
            pagelink: pagelink,
            action: "downloaded",
            contentClass: "libe entry",
          };
        }
      }
      let userId = this.currentUser.user.id;
      this.$axios
        .post("action-update/" + userId, actionJson)
        .then(function (response) {});
    },
    formatDescription(description) {
      if (description.length > 60) {
        return `${description.slice(0, 60)}...`;
      } else {
        return description;
      }
    },
    getPermissions() {
      let entityClassId = this.currentUser.user.entity
        ? this.currentUser.user.entity.entity_class_id
        : null;
      let userId = this.currentUser.user.id;
      this.$axios
        .post("resource/permissions/" + userId, {
          entityClassId: entityClassId,
        })
        .then(
          function (response) {
            const permissions = response.data.permissions || [];

            if (permissions.length > 0) {
              this.resourcePermissions = permissions;
            }
          }.bind(this)
        );
    },
    getResources() {
      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.$axios.get("resources", {}).then(
        function (response) {
          loader.hide();
          this.categories = response.data;
          for (let i = 0; i < this.categories.length; i++) {
            if (this.categories[i].resources.length > 0) {
              this.categories[i].resources.forEach((resource) => {
                if (this.checkResourcePermission(resource.id)) {
                  this.resourceList.push(resource);
                }
              });
            }

            this.subcategories = [];
            if (this.categories[i].subcategories.length > 0) {
              this.subcategories = this.categories[i].subcategories;
              for (let j = 0; j < this.subcategories.length; j++) {
                if (this.subcategories[j].resources.length > 0) {
                  this.subcategories[j].resources.forEach((resource) => {
                    if (this.checkResourcePermission(resource.id)) {
                      this.resourceList.push(resource);
                    }
                  });
                }
              }
            }
          }
        }.bind(this)
      );
    },
    checkResourcePermission(resourceId) {
      return this.currentUser && this.resourcePermissions.includes(resourceId);
    },
  },
};
</script>
