<template>
  <div
    class="curved-box"
    :class="
      onboardingData && onboardingData.header_only == '1' ? 'curved-top' : ''
    "
  >
    <div class="container onboarding">
      <h2>Getting started with Tarana</h2>
      <button type="button" class="copy-link float-end" @click="copyLink">
        <font-awesome-icon :icon="['fas', 'link']" /> Copy link
      </button>
      <div class="tooltip-content" :class="{ show: flag }">Link Copied!</div>
      <div
        class="row g-0 text-center d-none d-lg-block"
        v-if="categoryArray.length > 0"
      >
        <div class="col-md-12">
          <ul>
            <li
              v-for="(item, index) in categoryArray"
              :key="index"
              :class="{
                item: true,
                active: item.isActive,
              }"
            >
              <router-link
                :to="{ path: '/onboarding/' + item.urlparam }"
                @click="toggleActive(item)"
                ><font-awesome-icon :icon="['far', 'user']" /> &nbsp;{{
                  item.label
                }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div
        class="row g-0 text-center d-block d-lg-none"
        v-if="categoryArray.length > 0"
      >
        <div class="col-md-12">
          <select
            class="form-select mx-auto"
            name="onboarding_categories"
            id="onboarding_categories"
            @change="handleCategoryChange"
          >
            <template v-for="(item, index) in categoryArray">
              <option
                :value="item.id"
                :selected="currentParam == item.urlparam"
              >
                &#xf007; &nbsp;{{ item.label }}
              </option>
            </template>
          </select>
        </div>
      </div>
      <div
        class="align-items-center justify-content-center d-flex"
        v-if="onboardingData"
      >
        <div class="card" v-if="onboardingData.header_only == '0'">
          <div class="row g-0">
            <div class="col-md-6 content-order">
              <div class="card-body">
                <div class="boarding-content">
                  <a
                    class="card-title"
                    :href="
                      onboardingData.heading_link
                        ? onboardingData.heading_link
                        : '#'
                    "
                    target="_blank"
                    >{{ onboardingData.main_heading }}</a
                  >
                  <p
                    class="card-text"
                    v-html="onboardingData.main_description"
                  ></p>
                </div>
                <div class="btn-align" v-if="onboardingData.button_name">
                  <a
                    class="course-button"
                    :href="
                      onboardingData.button_link
                        ? onboardingData.button_link
                        : '#'
                    "
                    target="_blank"
                    >{{ onboardingData.button_name }}</a
                  >
                </div>
              </div>
            </div>
            <div
              class="col-md-6"
              :class="
                currentParam == 'noc-operator'
                  ? 'image-order grey-bg'
                  : 'image-order'
              "
            >
              <img
                :src="`${getAPIUrl}/storage/${onboardingData.file_path}`"
                :alt="onboardingData.file_name"
                v-if="onboardingData.file_name"
                class="img-fluid card-img centered-img"
                @click="enlargeImage(onboardingData.id)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="row g-0 text-center"
        v-if="onboardingData && onboardingData.header_only == '1'"
      >
        <div class="col-md-12">
          <div class="footer-main-title">
            {{ onboardingData.main_heading }}
          </div>
        </div>
      </div>
      <div
        class="row g-0"
        v-if="onboardingData && onboardingData.boarding_footers"
      >
        <template
          v-for="(footer, index) in onboardingData.boarding_footers"
          :key="index"
        >
          <div class="col-md-4 onboarding-footer">
            <div class="footer-sub-title">
              <a :href="getFooterLink(footer)" target="_blank">{{
                footer.title
              }}</a>
            </div>
            <div class="footer-content">
              <p v-html="footer.description"></p>
              <a :href="getFooterLink(footer)" target="_blank" id="learn-more"
                >Learn more</a
              >
            </div>
          </div>
          <hr
            id="hr-separator"
            v-if="
              (index + 1) % 3 === 0 &&
              index !== onboardingData.boarding_footers.length - 1
            "
          />
        </template>
      </div>
      <div class="row g-0 boarding-footer">
        <div class="col-md-12 pt-5">Looking for more resources?</div>
        <div class="col-md-12">
          <ul>
            <li>
              <router-link to="/resources" alt="View Resources"
                >View Resource Library</router-link
              >
            </li>
            <li>
              <a href="https://support.taranawireless.com" target="_blank"
                >Support Knowledge Base</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-if="onboardingData">
    <div
      class="dialog"
      :id="'popup-' + onboardingData.id"
      v-if="imgPopup[onboardingData.id]"
    >
      <div class="dialog-content">
        <button @click="closeImage(onboardingData.id)" class="close-icon">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <img
          :src="`${getAPIUrl}/storage/original/${onboardingData.file_name}`"
          class="img-fluid centered-img"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Tooltip } from "bootstrap";
export default {
  data() {
    return {
      successful: false,
      loading: false,
      flag: false,
      currentCategory: 0,
      currentParam: "",
      categories: [],
      categoryArray: [],
      subcategories: [],
      onboardingArray: [],
      onboardingData: [],
      onboardingFooter: [],
      imgPopup: {},
    };
  },
  computed: {
    getAPIUrl() {
      const base = this.$axios.defaults.baseURL;
      return base.split("/api")[0];
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentPage() {
      return this.$route.path.replace("/", "");
    },
  },
  methods: {
    copyLink() {
      this.flag = true;
      if (this.currentPage) {
        const el = document.createElement("textarea");
        el.value = window.location.origin + "/" + this.currentPage;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setTimeout(() => {
          this.flag = false;
        }, 1000);
      }
    },
    isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    },
    getFooterLink(data) {
      let resourceLink = "#";
      if (this.isValidUrl(data.title_link)) {
        resourceLink = data.title_link;
      } else {
        resourceLink = data.resource
          ? this.getAPIUrl + "/storage/" + data.resource.file_path
          : data.title_link;
      }
      return resourceLink;
    },
    handleCategoryChange(event) {
      const selectedCategoryId = event.target.value;
      let activePage = this.categoryArray.filter(
        (item) => item.id == selectedCategoryId
      );
      let parameter = activePage[0].urlparam;
      this.$router.push({ path: `/onboarding/${parameter}` });
      this.toggleActive(activePage[0]);
    },
    toggleActive(item) {
      this.currentCategory = item.id;
      this.categoryArray.forEach((item) => {
        item.isActive = false;
      });
      //let pagelink = this.currentPage + "/" + item.urlparam;
      this.currentParam = item.urlparam;
      let clickURL = "/onboarding/" + this.currentParam;
      localStorage.setItem("lastVisitedURL", clickURL);
      this.onboardingData = this.onboardingArray[item.id];
      item.isActive = true;
      if (this.currentUser) {
        this.updateActions(clickURL);
      }
    },
    enlargeImage(id) {
      this.imgPopup[id] = true;
    },
    closeImage(id) {
      this.imgPopup[id] = false;
    },
    getCustomerOnboarding() {
      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.onboardingArray = [];
      this.onboardingData = [];
      this.$axios.get("customer-onboarding", {}).then(
        function (response) {
          loader.hide();
          this.categories = response.data;
          this.categoryArray = [];
          for (let i = 0; i < this.categories.length; i++) {
            var urlparam = this.categories[i].name
              .replace(/\s+/g, "-")
              .toLowerCase();

            this.categoryArray.push({
              id: this.categories[i].id,
              label: this.categories[i].name,
              parent: this.categories[i].parent_id,
              urlparam: urlparam,
              onboarding: this.categories[i].onboarding,
              isActive: false,
            });

            if (this.categories[i].onboarding) {
              this.onboardingArray[this.categories[i].id] =
                this.categories[i].onboarding;
            }
          }

          if (this.$route.params.page_name) {
            let activePage = this.categoryArray.filter(
              (item) => item.urlparam == this.$route.params.page_name
            );
            this.toggleActive(activePage[0]);
          }
        }.bind(this)
      );
    },
    updateActions(pagelink) {
      let userId = this.currentUser.user.id;
      this.$axios
        .post("action-update/" + userId, {
          sessionId: this.currentUser.sessionId,
          pagelink: pagelink,
        })
        .then(function (response) {});
    },
  },
  created() {
    this.getCustomerOnboarding();
    this.$watch(
      () => this.$route.params,
      (newPath, oldPath) => {
        if (newPath.page_name == "cto") {
          let activePage = this.categoryArray.filter(
            (item) => item.urlparam == newPath.page_name
          );
          if (activePage[0]) {
            this.toggleActive(activePage[0]);
          }
        }
      },
      { immediate: true }
    );
  },
};
</script>
