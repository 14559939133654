import axios from "@/axios";

class AuthService {
  async login(user) {
    const response = await axios.post("login", {
      email: user.email,
      password: user.password,
    });
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }

  async logout(user) {
    const response = await axios.post("logout/" + user.user.id, {
      sessionId: user.sessionId,
    });
    return response.data;
  }

  async loginAsUser(token) {
    const response = await axios.post("verify-token", { token });
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }

  async register(user) {
    const response = await axios.post("signup", {
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
      password: user.password,
      password_confirmation: user.password_confirmation,
      title: user.title,
      entity_id: user.entity_id,
      organization: user.organization,
      city: user.city,
      postal: user.postal,
      country: user.country,
      aff: user.aff,
      serial: user.serial,
      user_case: user.user_case,
    });
    return response.data;
  }

  async forgot(user) {
    const response = await axios.post("forgot", {
      email: user.email,
    });
    return response.data;
  }

  async permissions(user) {
    let entityClassId = user.user.entity
      ? user.user.entity.entity_class_id
      : null;
    const response = await axios.post("permissions/" + user.user.id, {
      entityClassId: entityClassId,
    });
    return response.data;
  }
}

export default new AuthService();
