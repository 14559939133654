<template>
  <section class="h-100 pt-3">
    <div class="container h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-xl-5">
          <div class="row g-0">
            <div class="col-lg-12">
              <div class="p-md-5 mx-md-4">
                <div class="text-center">
                  <img
                    src="../assets/images/logo-black.png"
                    style="width: 185px"
                    alt="logo"
                  />
                </div>
                <div v-if="!success">
                  <Form
                    @submit="handleForgotPassword"
                    :validation-schema="schema"
                  >
                    <div class="form-outline my-4" v-if="!success">
                      <div
                        v-if="message"
                        class="alert alert-danger alert-dissmissable"
                        role="alert"
                      >
                        {{ message }}
                      </div>
                    </div>
                    <div class="form-group mb-4">
                      <label for="new_password">New Password</label>
                      <Field
                        name="new_password"
                        type="password"
                        class="input-custom mb-0"
                        placeholder="New Password"
                      />
                      <ErrorMessage
                        name="new_password"
                        class="error-feedback"
                      />
                      <p class="small-text">Make sure it's 8+ characters</p>
                    </div>

                    <div class="form-group mb-4">
                      <label for="confirm_password">Confirm Password</label>
                      <Field
                        name="confirm_password"
                        type="password"
                        class="input-custom mb-0"
                        placeholder="Confirm Password"
                      />
                      <ErrorMessage
                        name="confirm_password"
                        class="error-feedback"
                      />
                      <p class="small-text">Make sure it's 8+ characters</p>
                    </div>

                    <div class="text-center pt-1 pb-1">
                      <button class="save-btn" type="submit">
                        Reset Password</button
                      ><br />
                      <router-link to="/login" class="text-indigo"
                        >Login</router-link
                      >
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-center pb-4"
                    >
                      <p class="mb-0 me-2">Don't have an account?</p>
                      <router-link to="/register" class="text-indigo"
                        >Register here</router-link
                      >
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8 mb-2" v-if="success">
          <div class="mb-5 text-success text-center">
            <h1>
              Congratulations! Your new password has been successfully saved.
              Please login
              <router-link to="/login" class="text-indigo">here</router-link>.
            </h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

const schema = yup.object().shape({
  new_password: yup
    .string()
    .required("New Password is required!")
    .min(8, "Must be at least 8 characters!")
    .test(
      "isValidPass",
      "The password requires an uppercase, lowercase, number and special character",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    ),
  confirm_password: yup
    .string()
    .required("Confirm Password is required!")
    .oneOf([yup.ref("new_password")], "Passwords must match!"),
});

export default {
  name: "Reset Password",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      message: "",
      schema,
      success: false,
    };
  },
  mounted() {
    this.checkUserLoggedin();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    checkUserLoggedin() {
      if (this.currentUser) {
        this.$store.dispatch("auth/logout", this.currentUser).then(
          () => {
            localStorage.clear();
            location.reload();
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    handleForgotPassword(user) {
      this.loading = true;
      this.success = false;
      this.$axios
        .post("/reset-password", {
          password: user.new_password,
          password_confirmation: user.confirm_password,
          token: this.$route.params.token,
          email: decodeURIComponent(this.$route.params.email),
        })
        .then(
          (result) => {
            this.success = true;
            this.message = result.message;
          },
          (error) => {
            this.loading = false;
            this.success = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
  },
};
</script>
