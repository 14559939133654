// auth.module.js
import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

const state = {
  permissions: [],
  status: { loggedIn: Boolean(user) },
  user,
};

const getters = {
  userPermissions: (state) => state.permissions,
};

const actions = {
  login({ commit }, user) {
    return AuthService.login(user).then(
      (user) => {
        commit("loginSuccess", user);
        return Promise.resolve(user);
      },
      (error) => {
        commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },

  loginAsUser({ commit }, user) {
    return AuthService.loginAsUser(user).then(
      (user) => {
        commit("loginSuccess", user);
        return Promise.resolve(user);
      },
      (error) => {
        commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },

  logout({ commit }, user) {
    return AuthService.logout(user).then(
      (response) => {
        commit("logoutSuccess");
        return Promise.resolve(response);
      },
      (error) => {
        commit("logoutFailure");
        return Promise.reject(error);
      }
    );
  },

  register({ commit }, user) {
    return AuthService.register(user).then(
      (response) => {
        commit("registerSuccess");
        return Promise.resolve(response);
      },
      (error) => {
        commit("registerFailure");
        return Promise.reject(error);
      }
    );
  },
  forgot({ commit }, user) {
    return AuthService.forgot(user).then(
      (response) => {
        // commit('passwordRequestSuccess');
        return Promise.resolve(response);
      },
      (error) => {
        // commit('passwordRequestFailure');
        return Promise.reject(error);
      }
    );
  },
  async permissions({ commit }, user) {
    try {
      const response = await AuthService.permissions(user);
      const permissions = response.permissions;
      commit("setPermissions", permissions);
      return Promise.resolve(response);
    } catch (error) {
      // Handle permission fetching error here
      console.error("Permission fetching error:", error);
      return Promise.reject(error);
    }
  },
};

const mutations = {
  loginSuccess(state, user) {
    state.status.loggedIn = true;
    state.user = user;
  },
  loginFailure(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  logout(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  registerSuccess(state) {
    state.status.loggedIn = true;
    state.user = user;
  },
  registerFailure(state) {
    state.status.loggedIn = false;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
};

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
