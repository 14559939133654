<template>
  <div id="tarana_resources" class="min-vh-100 d-flex flex-column">
    <div class="container-fluid pt-2">
      <div class="row">
        <div class="col-md-12 mb-4 d-block d-md-none">
          <div class="dropdown">
            <button
              class="btn btn-outline-secondary dropdown-toggle text-indigo"
              type="button"
              id="resource-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{
                selectedSubcategory
                  ? convertToTitleCase(selectedSubcategory) +
                    " (" +
                    getCountInSubcategory(selectedSubcategory, "theme") +
                    ")"
                  : "All Resources (" + totalResources + ")"
              }}
            </button>
            <div class="dropdown-menu" aria-labelledby="resourceDropdown">
              <div
                class="dropdown-item"
                role="presentation"
                @click="gotoLink('All')"
              >
                <div
                  class="card-text ps-3 py-1 cursor-pointer text-indigo"
                  :class="{ active: selectedSubcategory == '' }"
                >
                  All Resources ({{ totalResources }})
                </div>
              </div>
              <template
                v-for="(subcategories, category) in categories"
                :key="category"
              >
                <div
                  v-if="subcategories && subcategories.length"
                  class="dropdown-item"
                >
                  <div
                    class="category-disabled text-indigo ps-3"
                    role="presentation"
                  >
                    <b>{{ convertToTitleCase(category) }}</b>
                  </div>
                  <div
                    class="card-text ps-3 py-1 cursor-pointer"
                    v-if="category == 'resourceType'"
                  >
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input tarana-check"
                        :checked="areAllChecked"
                        @click="handleCheckAll"
                      />
                      <label
                        class="form-check-label card-text cursor-pointer text-indigo"
                        @click="handleCheckAll"
                      >
                        {{ areAllChecked ? "Uncheck All" : "Check All" }}
                      </label>
                    </div>
                  </div>
                  <div
                    class="card-text ps-4 py-1"
                    v-for="subcategory in subcategories"
                    :class="{ active: selectedSubcategory == subcategory }"
                    :key="subcategory.id"
                  >
                    <div
                      class="cursor-pointer text-indigo"
                      v-if="category == 'theme'"
                      @click="handleCategoryChange(subcategory)"
                    >
                      {{ convertToTitleCase(subcategory) }} ({{
                        getCountInSubcategory(subcategory, category)
                      }})
                    </div>
                    <div v-else class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :id="'checkbox-' + subcategory"
                        @click="handleCategoryChange(subcategory)"
                        :checked="resourceCategories.includes(subcategory)"
                      />
                      <label
                        class="form-check-label card-text cursor-pointer text-indigo"
                        @click.stop.prevent
                        @click="handleCategoryChange(subcategory)"
                        :for="'checkbox-' + subcategory"
                      >
                        {{ convertToTitleCase(subcategory) }} ({{
                          getCountInSubcategory(subcategory, category)
                        }})
                      </label>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3 col-xl-2 d-none d-md-block">
          <div class="tarana-sidebar" ref="sidebarContainer">
            <div class="category-list ps-2">
              <div
                class="col-md-12 card-text p-1 border-bottom cursor-pointer"
                :class="{ active: !selectedSubcategory }"
                @click="gotoLink('All')"
              >
                All Resources ({{ totalResources }})
              </div>
              <div
                v-for="(subcategories, category) in categories"
                :key="category"
                class="category-section"
              >
                <div class="category-main">
                  {{ convertToTitleCase(category) }}
                </div>
                <div v-if="subcategories && subcategories.length">
                  <div
                    class="card-text ps-1 py-1 cursor-pointer"
                    v-if="category == 'resourceType'"
                  >
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input tarana-check"
                        :checked="areAllChecked"
                        @click="handleCheckAll"
                      />
                      <label
                        class="form-check-label card-text cursor-pointer"
                        @click="handleCheckAll"
                      >
                        {{ areAllChecked ? "Uncheck All" : "Check All" }}
                      </label>
                    </div>
                  </div>
                  <div
                    v-for="subcategory in subcategories"
                    :key="subcategory"
                    class="card-text ps-3 py-1 cursor-pointer"
                    :class="{ active: selectedSubcategory == subcategory }"
                    @click="handleCategoryChange(subcategory)"
                  >
                    <span v-if="category == 'theme'"
                      >{{ convertToTitleCase(subcategory) }} ({{
                        getCountInSubcategory(subcategory, category)
                      }})</span
                    >
                    <div class="form-check" v-if="category == 'resourceType'">
                      <input
                        type="checkbox"
                        class="form-check-input tarana-check"
                        :id="'checkbox-' + subcategory"
                        :checked="resourceCategories.includes(subcategory)"
                      />
                      <label
                        class="form-check-label card-text cursor-pointer"
                        @click="handleCategoryChange(subcategory)"
                        :for="'checkbox-' + subcategory"
                      >
                        {{ convertToTitleCase(subcategory) }} ({{
                          getCountInSubcategory(subcategory, category)
                        }})
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-9 col-xl-10">
          <div class="row" ref="rowContainer" id="resource-row">
            <div class="col-12 mb-4">
              <div class="float-start">
                <h2 class="text-left main_head_resource">
                  {{ convertToTitleCase(selectedSubcategory) }}
                </h2>
              </div>
              <div class="float-end">
                <div class="row">
                  <div class="col-9 search-pad">
                    <input
                      type="search"
                      name="title"
                      v-model="searchTitle"
                      class="form-control border border-2"
                      placeholder="Search Resources"
                      @input="resourceSearch"
                    />
                  </div>
                  <div class="col-3 ps-0 search-pad">
                    <button
                      type="button"
                      class="btn clear-button"
                      @click="clearInput"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 mb-4 theme-description"
              v-if="themeDefinitions[selectedSubcategory]"
            >
              {{ themeDefinitions[selectedSubcategory] }}
            </div>
            <div
              v-for="(post, index) in top5AllResources"
              :key="post.id"
              class="col mb-4"
              v-if="!selectedSubcategory"
            >
              <div
                class="card card-bg h-100 cursor-pointer"
                @click="gotoPage(post.link)"
              >
                <div class="position-relative">
                  <img
                    :src="post.image"
                    class="card-img-top"
                    :alt="post.title"
                  />
                </div>
                <div class="card-body">
                  <h4 class="card-title">{{ post.title }}</h4>
                  <div class="badge card-badge">
                    {{ convertToTitleCase(post.canonical_resource_type) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mb-4" v-if="top5ThemeResources.length > 0">
              <h2 class="text-left main_head_resource">Top Resources</h2>
            </div>
            <div
              v-if="selectedSubcategory"
              v-for="(post, index) in top5ThemeResources"
              :key="post.id"
              class="col mb-4"
            >
              <div
                class="card card-bg h-100 cursor-pointer"
                @click="gotoPage(post.link)"
              >
                <div class="position-relative">
                  <img
                    :src="post.image"
                    class="card-img-top"
                    :alt="post.title"
                  />
                </div>
                <div class="card-body">
                  <h4 class="card-title">{{ post.title }}</h4>
                  <div class="badge card-badge">
                    {{ convertToTitleCase(post.canonical_resource_type) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" v-for="(item, category) in resourceCards">
              <div class="card card-tile mb-4" v-if="item.length">
                <div class="card-header">
                  {{ convertToTitleCase(category) }}
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" v-for="resource in item">
                    <div class="py-2 card-text">
                      <a
                        :href="resource.link"
                        target="_blank"
                        class="resource-title"
                        ><font-awesome-icon
                          :icon="['fa', checkLinkType(resource)]"
                          class="pad-right"
                        />{{ resource.title }}
                      </a>
                      <span
                        class="resource-date"
                        v-if="resource.revision_date"
                        >{{ formatDate(resource.revision_date) }}</span
                      ><br />
                      <div class="mt-2">
                        <span class="fw-700" v-html="resource.description">
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      posts: [],
      totalResources: 0,
      totalThemeCount: [],
      selectedSubcategory: "All",
      sidebarHeight: 0,
      rowHeight: 0,
      resourceList: [],
      filterResources: [],
      filterResourceCategories: [],
      filterIds: [],
      resourceCards: [],
      categories: [],
      resourceDataForFilter: [],
      resourceCategories: [],
      top5ThemeResources: [],
      top5AllResources: [],
      themeDefinitions: {},
      resourceTypeOrders: {},
      urlResourceType: "",
      searchTitle: "",
      areAllChecked: false,
      isSearchValid: false,
      showClear: false,
    };
  },
  methods: {
    clearInput() {
      this.searchTitle = "";
      this.showClear = false;
      this.getMainSiteResources();
    },
    resourceSearch() {
      this.isSearchValid = this.searchTitle != "" ? true : false;
      if (this.isSearchValid) {
        if (this.searchTitle.length > 2) {
          if (this.selectedSubcategory) {
            this.filterBySubcategory("All");
          }
          this.$axios
            .post("resources-search", { search: this.searchTitle })
            .then(
              function (response) {
                let filtered = response.data;
                this.filterIds = [];
                if (filtered.length > 0) {
                  filtered.forEach((post) => {
                    if (!this.filterIds.includes(post.id)) {
                      this.filterIds.push(post.id);
                    }
                  });
                }
                this.filterAllResources();
              }.bind(this)
            );
        }
      } else {
        this.getMainSiteResources();
      }
    },
    filterAllResources() {
      this.filterResources = [];
      this.resourceList = [];
      this.totalResources = 0;

      let filtered = {};
      let filteredCategories = [];
      this.filterPostsData();
      if (this.selectedSubcategory) {
        this.resourceList[this.selectedSubcategory] = [];
      }

      let total = 0;
      for (const type of this.filterResourceCategories) {
        if (this.resourceDataForFilter[type]) {
          filtered[type] = this.resourceDataForFilter[type].filter((resource) =>
            this.filterIds.includes(parseInt(resource.id))
          );
          if (this.selectedSubcategory) {
            this.resourceList[this.selectedSubcategory][type] = filtered[type];
          }
          if (filtered[type].length) {
            total = total + filtered[type].length;
            filteredCategories.push(type);
          }
        }
      }
      if (!this.selectedSubcategory) {
        this.totalResources = total;
      } else {
        this.totalThemeCount[this.selectedSubcategory] = total;
      }
      this.resourceCategories = filteredCategories;
      this.resourceDataForFilter = filtered;
      this.filterResources = this.resourceDataForFilter;
      this.areAllChecked =
        this.resourceCategories.length ===
        this.categories["resourceType"].length;
      this.getAllResources();
    },
    filterResourceData(subcat) {
      if (subcat) {
        const index = this.resourceCategories.indexOf(subcat);
        if (index !== -1) {
          this.resourceCategories.splice(index, 1);
        } else {
          this.resourceCategories.push(subcat);
        }
      }

      this.areAllChecked =
        this.resourceCategories.length ===
        this.categories["resourceType"].length;

      const filterData = this.resourceCategories.reduce((acc, cat) => {
        acc[cat] = { ...this.resourceDataForFilter[cat] };
        return acc;
      }, {});

      this.filterResources = { ...filterData };
      this.getAllResources();
    },
    formatDate(dateString) {
      const date = new Date(dateString)
        .toLocaleDateString("en-US", {
          timeZone: "UTC",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, ".");
      return date;
    },
    checkLinkType(post) {
      let linkType = "link";
      const resourceTypeFiles = ["white-papers", "data-sheets"];
      const resourceTypeVideos = ["videos", "webinars"];
      if (resourceTypeVideos.includes(post.canonical_resource_type)) {
        linkType = "video";
      } else if (resourceTypeFiles.includes(post.canonical_resource_type)) {
        linkType = "file";
      }
      return linkType;
    },
    gotoPage(link) {
      window.open(link, "_blank");
    },
    gotoLink(canonical) {
      let urlparam = canonical == "All" ? "" : canonical;
      window.location.href = "/tarana-resources#" + urlparam;
      if (this.urlResourceType == "") {
        location.reload();
      }
      if (this.urlResourceType != "" && this.selectedSubcategory != "") {
        location.reload();
      }
    },
    getMainSiteResources() {
      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.$axios.get("resources-json", {}).then(
        function (response) {
          loader.hide();
          this.themeDefinitions = response.data.theme_definitions;
          this.resourceTypeOrders = response.data.resource_type_listing_order;
          this.posts = response.data.resources;
          this.totalResources = this.posts.length;
          this.filterPostsData();
        }.bind(this)
      );
    },
    filterPostsData() {
      this.resourceList = [];
      this.filterResources = [];
      this.categories = {
        theme: [],
        resourceType: [],
      };
      this.categoryResources = [];
      this.resourceDataForFilter = [];
      this.resourceCategories = [];
      let themeCount = [];

      let grouped = {};
      let resourceIds = [];
      this.posts.forEach((post) => {
        // Iterate over each theme of the resource
        post.canonical_themes.forEach((themeObj) => {
          const theme = themeObj.theme;
          const resourceType = post.canonical_resource_type;
          themeCount[theme] = (themeCount[theme] || 0) + 1;

          if (!grouped[theme]) {
            grouped[theme] = {};
            this.categories.theme.push(theme);
            this.categoryResources[theme] = [];
          }

          if (!this.resourceDataForFilter[resourceType]) {
            this.resourceDataForFilter[resourceType] = [];
          }

          if (!grouped[theme][resourceType]) {
            grouped[theme][resourceType] = [];
            this.categoryResources[theme].push(resourceType);
          }

          grouped[theme][resourceType].push(post);

          if (
            !resourceIds.includes(post.id) &&
            this.selectedSubcategory == ""
          ) {
            this.resourceDataForFilter[resourceType].push(post);
            resourceIds.push(post.id);
          }

          if (
            !this.categories.resourceType.includes(resourceType) &&
            this.selectedSubcategory == ""
          ) {
            this.categories.resourceType.push(resourceType);
            this.resourceCategories.push(resourceType);
          }
        });
      });

      this.totalThemeCount = themeCount;
      this.resourceList = grouped;
      if (this.selectedSubcategory != "") {
        let category = this.selectedSubcategory;
        this.categories.resourceType = [];
        this.categories.resourceType = this.categoryResources[category];
        this.resourceCategories = Object.values(
          this.categoryResources[category]
        );
        this.resourceDataForFilter = this.resourceList[category];
      } else {
        this.resourceDataForFilter = { ...this.resourceDataForFilter };
      }

      // Sort resource types based on resource type order
      this.categories.resourceType.sort((a, b) => {
        const orderA = this.getResourceTypeOrder(a);
        const orderB = this.getResourceTypeOrder(b);
        return orderA - orderB;
      });

      this.areAllChecked = true;
      this.filterResourceCategories = this.resourceCategories;
      this.filterResources = this.resourceDataForFilter;
      if (this.urlResourceType != "") {
        this.resourceCategories = [];
        if (this.urlResourceType.includes(",")) {
          this.resourceCategories = this.urlResourceType.split(",");
        } else {
          this.resourceCategories.push(this.urlResourceType);
        }
        this.filterResourceData();
      }
      this.getAllResources();
    },
    getResourceTypeOrder(type) {
      const orderEntry = this.resourceTypeOrders.find(
        (entry) => entry.type === type
      );
      return orderEntry ? orderEntry.order : this.resourceTypeOrders.length + 1;
    },
    convertToTitleCase(str) {
      if (!str) {
        return "Top Resources";
      } else if (str == "theme") {
        return "Themes";
      } else if (str == "resourceType") {
        return "Resource Types";
      } else {
        return str
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      }
    },
    getCountInSubcategory(subcategory, maincategory) {
      if (this.selectedSubcategory != "" && maincategory == "resourceType") {
        return this.resourceList[this.selectedSubcategory][subcategory].length;
      } else if (maincategory == "theme") {
        return this.totalThemeCount[subcategory];
      } else {
        return this.resourceDataForFilter[subcategory].length;
      }
    },
    getAllResources() {
      const dataArray = Object.keys(this.filterResources).map((key) => ({
        type: key,
        ...this.filterResources[key],
      }));

      dataArray.sort((a, b) => {
        const orderA = this.getResourceTypeOrder(a.type);
        const orderB = this.getResourceTypeOrder(b.type);
        return orderA - orderB;
      });

      this.resourceCards = [];
      this.top5ThemeResources = [];
      this.top5AllResources = [];
      let tarana_resources = [];
      Object.keys(dataArray).forEach((maincategory) => {
        var dataobj = dataArray[maincategory];
        tarana_resources[dataobj.type] = [];
        let resource_ids = [];
        Object.keys(dataobj).forEach((subcatname) => {
          if (
            dataobj[subcatname].top5_all_resources == "true" &&
            this.selectedSubcategory == ""
          ) {
            if (!resource_ids.includes(dataobj[subcatname].id)) {
              this.top5AllResources.push(dataobj[subcatname]);
              resource_ids.push(dataobj[subcatname].id);
            }
          }
          if (
            dataobj[subcatname].canonical_themes &&
            this.selectedSubcategory != ""
          ) {
            dataobj[subcatname].canonical_themes.forEach((theme) => {
              if (
                theme.top5 == "true" &&
                this.selectedSubcategory == theme.theme
              ) {
                this.top5ThemeResources.push(dataobj[subcatname]);
              }
            });
          }
          if (dataobj[subcatname] != dataobj.type) {
            tarana_resources[dataobj.type].push(dataobj[subcatname]);
          }
        });
      });

      // Sort resources by revision_date in descending order
      if (this.top5AllResources.length > 0) {
        this.top5AllResources.sort(
          (a, b) => new Date(b.revision_date) - new Date(a.revision_date)
        );
      }

      if (this.top5ThemeResources.length > 0) {
        this.top5ThemeResources.sort(
          (a, b) => new Date(b.revision_date) - new Date(a.revision_date)
        );
      }

      Object.keys(tarana_resources).forEach((type) => {
        tarana_resources[type].sort((a, b) => {
          return new Date(b.revision_date) - new Date(a.revision_date);
        });
      });
      this.resourceCards = { ...tarana_resources };
      this.handleResize();
    },
    handleCheckAll() {
      this.areAllChecked = !this.areAllChecked;
      this.resourceCategories = this.areAllChecked
        ? [...this.categories["resourceType"]]
        : [];
      this.filterResourceData();
    },
    handleCategoryChange(subcategory) {
      if (this.categories.theme.includes(subcategory)) {
        this.gotoLink(subcategory);
      } else {
        this.filterResourceData(subcategory);
      }
    },
    filterBySubcategory(canonical) {
      this.selectedSubcategory = canonical == "All" ? "" : canonical;
      this.getMainSiteResources();
    },
    handleResize() {
      this.$nextTick(this.getRowHeight);
    },
    getRowHeight() {
      // Accessing the div element using the ref
      const sidebarElement = this.$refs.sidebarContainer;
      if (sidebarElement) {
        this.sidebarHeight = sidebarElement.offsetHeight;
      }

      const rowElement = this.$refs.rowContainer;
      if (rowElement) {
        this.rowHeight = rowElement.offsetHeight;
      }
      let pageHeight = document.body.scrollHeight;

      window.parent.postMessage(
        {
          type: "contentLoaded",
          height: pageHeight,
          row_height: this.rowHeight,
          sidebar_height: this.sidebarHeight,
          canonical: this.selectedSubcategory,
        },
        "*"
      );
    },
  },
  created() {
    try {
      if (this.$route.query.type) {
        this.urlResourceType = this.$route.query.type;
      }
      var currentURL = this.$route.hash;
      if (currentURL) {
        var queryString = window.location.hash.substring(1);
        const urlParts = queryString.split("?");
        var hashValue = urlParts[0];
        this.urlResourceType = urlParts[1]
          ? urlParts[1].split("=")[1].replace(/%20/g, "")
          : "";
        if (hashValue) {
          this.filterBySubcategory(hashValue);
        } else {
          this.filterBySubcategory("All");
        }
      } else {
        this.filterBySubcategory("All");
      }
    } catch (error) {
      this.filterBySubcategory("All");
    }
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // Remove the resize event listener when the component is destroyed
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
