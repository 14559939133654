<template>
  <div class="container bg-outline-danger">
    <div class="row py-5">
      <div class="col-sm-12 my-5">
        <div class="card-text text-center mb-5">
          <h1 class="text-success" v-if="successful">
            Your account is successfully confirmed. Please
            <router-link :to="{ path: '/login' }" tag="button"
              >log in</router-link
            >
            to continue.
          </h1>
          <h1 class="text-danger" v-else>{{ message }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm-email",
  data() {
    return {
      message: "",
      successful: false,
    };
  },
  mounted() {
    const token = this.$route.params.token;
    if (token) {
      this.confirmEmail(token);
    }
  },
  methods: {
    confirmEmail(token) {
      this.successful = false;
      let loader = this.$loading.show({
        canCancel: true,
        color: "#0367a9",
      });
      this.$axios.post("confirm-email", { token }).then(
        (response) => {
          loader.hide();
          if (response.data.success) {
            this.successful = true;
          }
        },
        (error) => {
          loader.hide();
          this.successful = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
