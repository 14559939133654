<template>
  <nav class="navbar subnav d-none d-lg-block">
    <div class="container">
      <div class="main">
        <a href="#">My Account</a>
      </div>
    </div>
  </nav>
  <nav class="navbar mobview d-block d-lg-none">
    <div class="border-bottom">
      <div class="container main">
        <a href="#">My Account</a>
      </div>
    </div>
  </nav>
  <div class="min-vh-100 d-flex flex-column mtop mb-5">
    <section>
      <div class="container account">
        <div class="submenu">
          <ul class="text-left px-0">
            <li
              class="btn item"
              :class="activeTab === 'profile' ? 'active' : ''"
              @click="activeTab = 'profile'"
            >
              <a href="#" @click="activeTab = 'profile'">Profile</a>
            </li>
            <li
              class="btn item"
              :class="activeTab === 'password' ? 'active' : ''"
              @click="activeTab = 'password'"
            >
              <a href="#" @click="activeTab = 'password'">Password</a>
            </li>
          </ul>
        </div>
        <!-- Profile -->
        <div class="mt-5" v-if="activeTab === 'profile'">
          <div class="form-outline my-4" v-if="message">
            <div
              class="alert alert-dissmissable"
              :class="successful ? 'alert-success' : 'alert-danger'"
              role="alert"
            >
              {{ message }}
            </div>
          </div>
          <Form @submit="updateProfile" :validation-schema="profileSchema">
            <div class="row mb-5">
              <div class="col-md-4 col-sm-12">
                <p class="sub-title">Profile information</p>
                <div class="form-group">
                  <label for="email">Email address</label>
                  <Field
                    type="email"
                    name="email"
                    class="input-custom mb-0"
                    placeholder="Email Address"
                    v-model="currentUser.user.email"
                  />
                  <ErrorMessage name="email" class="error-feedback" />
                </div>
              </div>
            </div>
            <div class="row">
              <p class="sub-title">Organization information</p>
              <div class="col-md-8">
                <div class="row mb-3">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="fname">First Name</label>
                      <Field
                        type="text"
                        class="input-custom mb-0"
                        placeholder="First Name"
                        v-model="currentUser.user.firstname"
                        name="firstname"
                      />
                      <ErrorMessage name="firstname" class="error-feedback" />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 mb-3">
                    <div class="form-group">
                      <label for="lname">Last Name</label>
                      <Field
                        type="text"
                        class="input-custom mb-0"
                        placeholder="First Name"
                        v-model="currentUser.user.lastname"
                        name="lastname"
                      />
                      <ErrorMessage name="lastname" class="error-feedback" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row mb-3">
                  <div class="col-md-6 col-sm-12 mb-3">
                    <div class="form-group">
                      <label for="title">Title</label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        v-model="currentUser.user.title"
                        class="input-custom mb-0"
                        placeholder="Title"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 mb-3">
                    <div class="form-group">
                      <label for="org">Organization</label>
                      <input
                        type="text"
                        name="org"
                        id="org"
                        v-model="currentUser.user.organization"
                        class="input-custom mb-0"
                        placeholder="Organization"
                        disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row mb-3">
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="city">City</label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        v-model="currentUser.user.city"
                        class="input-custom mb-0"
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <div class="form-group">
                      <label for="country">Country</label>
                      <input
                        type="text"
                        name="country"
                        id="country"
                        v-model="currentUser.user.country"
                        class="input-custom mb-0"
                        placeholder="Country"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-4 col-sm-12 text-center">
                  <input type="submit" class="save-btn" value="Save Changes" />
                </div>
              </div>
            </div>
          </Form>
        </div>
        <!-- Password -->
        <div class="mt-5" v-if="activeTab === 'password'">
          <div class="form-outline my-4" v-if="successful">
            <div class="alert alert-dissmissable alert-success" role="alert">
              {{ message }}
            </div>
          </div>
          <Form @submit="changePassword" :validation-schema="passwordSchema">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <p class="sub-title">Change Your Password</p>
                <div class="row">
                  <div class="form-group mb-5">
                    <label for="email">Current password</label>
                    <Field
                      type="password"
                      name="current_password"
                      class="input-custom mb-0"
                      placeholder="Current Password *"
                      v-model="currentPassword"
                    />
                    <div class="error-feedback" v-if="passwordError">
                      {{ message }}
                    </div>
                    <ErrorMessage
                      name="current_password"
                      class="error-feedback"
                    />
                  </div>
                  <div class="form-group mb-5">
                    <label for="email">New password</label>
                    <Field
                      type="password"
                      name="password"
                      class="input-custom mb-0"
                      placeholder="New Password *"
                      v-model="newPassword"
                    />
                    <ErrorMessage name="password" class="error-feedback" />
                    <p class="small-text">Leave blank if no change desired</p>
                  </div>
                  <div class="form-group mb-5">
                    <label for="email">Confirm new password</label>
                    <Field
                      type="password"
                      name="password_confirmation"
                      class="input-custom mb-0"
                      placeholder="Confirm Password *"
                      v-model="confirmPassword"
                    />
                    <ErrorMessage
                      name="password_confirmation"
                      class="error-feedback"
                    />
                    <p class="small-text">Leave blank if no change desired</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4 col-sm-12 text-center">
                <input type="submit" class="save-btn" value="Change Password" />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

const profileSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required!")
    .email("Email is invalid!")
    .max(50, "Must be maximum 50 characters!"),
  firstname: yup.string().required("First name is required!"),
  lastname: yup.string().required("Last name is required!"),
});

const passwordSchema = yup.object().shape({
  current_password: yup
    .string()
    .required("Current Password is required!")
    .min(6, "Must be at least 6 characters!")
    .max(40, "Must be maximum 40 characters!"),
  password: yup
    .string()
    .required("New Password is required!")
    .min(8, "Must be at least 8 characters!")
    .test(
      "isValidPass",
      "The password requires an uppercase, lowercase, number and special character",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 3;
        const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    ),
  password_confirmation: yup
    .string()
    .required("Confirm Password is required!")
    .oneOf([yup.ref("password")], "Passwords must match!"),
});

export default {
  name: "Account",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      activeTab: "profile",
      newPassword: "",
      currentPassword: "",
      confirmPassword: "",
      profileSchema,
      passwordSchema,
      message: "",
      successful: false,
      passwordError: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push({ path: "/login" });
    } else {
      this.updateActions("/account");
    }
  },
  methods: {
    changePassword() {
      this.$axios
        .post("password/change", {
          current_password: this.currentPassword,
          password: this.newPassword,
          password_confirmation: this.confirmPassword,
          user_id: this.currentUser.user.id,
          sessionId: this.currentUser.sessionId,
        })
        .then((response) => {
          this.message = response.data.message;
          this.successful = true;
          this.passwordError = false;
        })
        .catch((error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.passwordError = true;
          this.successful = false;
        });
    },
    updateProfile() {
      let userId = this.currentUser.user.id;
      this.$axios
        .post("update/" + userId, {
          firstname: this.currentUser.user.firstname,
          lastname: this.currentUser.user.lastname,
          title: this.currentUser.user.title,
          organization: this.currentUser.user.organization,
          city: this.currentUser.user.city,
          country: this.currentUser.user.country,
          email: this.currentUser.user.email,
          token: this.currentUser.token,
          sessionId: this.currentUser.sessionId,
        })
        .then((response) => {
          this.message = response.data.message;
          this.successful = true;
          if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
        })
        .catch((error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
        });
    },
    updateActions(pagelink) {
      let userId = this.currentUser.user.id;
      this.$axios
        .post("action-update/" + userId, {
          sessionId: this.currentUser.sessionId,
          pagelink: pagelink,
        })
        .then(function (response) {});
    },
  },
};
</script>
