<template>
  <my-header v-if="!hideHeader"></my-header>
  <router-view></router-view>
  <my-footer v-if="!hideFooter"></my-footer>
</template>

<script>
import Header from "./components/layouts/header.vue";
import Footer from "./components/layouts/footer.vue";

export default {
  name: "App",
  components: {
    "my-header": Header,
    "my-footer": Footer,
  },
  data() {
    return {
      hideHeader: true, // Set to true to hide header for Resources component
      hideFooter: true, // Set to true to hide footer for Resources component
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === "Tarana Resources") {
        this.hideHeader = true;
        this.hideFooter = true;
      } else {
        this.hideHeader = false; // Show header for other components
        this.hideFooter = false; // Show footer for other components
      }
    },
  },
};
</script>
