<template>
  <footer v-if="!['login', 'register', 'onboarding'].includes(currentPage)">
    <div class="container">
      <div class="row">
        <div class="col-md-10 d-none d-sm-block">
          <div class="row">
            <div class="col-md-4 col-sm-6">
              <div class="footer">
                <h4>Support</h4>
                <ul>
                  <li>
                    <a href="https://support.taranawireless.com" target="_blank"
                      >Help Center</a
                    >
                  </li>
                  <li>
                    <a href="mailto:support@taranawireless.com"
                      >Contact Support</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://support.taranawireless.com/hc/en-us/community/topics"
                      target="_blank"
                      >Community</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!--- END COL -->
            <!--<div class="col-md-4 col-sm-6">
              <div class="footer">
                <h4>Resources</h4>
                <ul>
                  <li><a href="#">Downloads & Collateral</a></li>
                  <li><a href="#">Documentation</a></li>
                  <li><a href="#">Partner Program</a></li>
                  <li><a href="#">Tarana Training</a></li>
                </ul>
              </div>
            </div>-->
            <!--- END COL -->
            <div class="col-md-4 col-sm-6">
              <div class="footer">
                <h4>Company</h4>
                <ul>
                  <li>
                    <a href="https://www.taranawireless.com" target="_blank"
                      >Corporate Website</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.taranawireless.com/blog/"
                      target="_blank"
                      >Blog</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.taranawireless.com/impact/#case-studies"
                      target="_blank"
                      >Case Studies</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.taranawireless.com/careers/"
                      target="_blank"
                      >Careers</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!--- END COL -->
            <div class="col-md-4 col-sm-6">
              <div class="footer">
                <h4>Quicklinks</h4>
                <ul>
                  <li>
                    <a
                      href="https://portal.cloud.taranawireless.com/operator-portal"
                      target="_blank"
                      >Tarana Cloud Suite Login</a
                    >
                  </li>
                  <li>
                    <a href="https://learn.taranawireless.com" target="_blank"
                      >Tarana Academy Online</a
                    >
                  </li>
                  <!--<li><a href="#">Partner Login</a></li>-->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-10 d-block d-sm-none px-0">
          <div class="accordion accordion-flush" id="footerMobile">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingSupport">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSupport"
                  aria-expanded="false"
                  aria-controls="flush-collapseSupport"
                >
                  Support
                </button>
              </h2>
              <div
                id="flush-collapseSupport"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingSupport"
                data-bs-parent="#footerMobile"
              >
                <div class="accordion-body footer">
                  <ul>
                    <li>
                      <a
                        href="https://support.taranawireless.com"
                        target="_blank"
                        >Help Center</a
                      >
                    </li>
                    <li>
                      <a href="mailto:support@taranawireless.com"
                        >Contact Support</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://support.taranawireless.com/hc/en-us/community/topics"
                        target="_blank"
                        >Community</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--<div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingResources">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseResources"
                  aria-expanded="false"
                  aria-controls="flush-collapseResources"
                >
                  Resources
                </button>
              </h2>
              <div
                id="flush-collapseResources"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingResources"
                data-bs-parent="#footerMobile"
              >
                <div class="accordion-body footer">
                  <ul>
                    <li><a href="#">Downloads & Collateral</a></li>
                    <li><a href="#">Documentation</a></li>
                    <li><a href="#">Partner Program</a></li>
                    <li><a href="#">Tarana Training</a></li>
                  </ul>
                </div>
              </div>
            </div>-->
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingCompany">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseCompany"
                  aria-expanded="false"
                  aria-controls="flush-collapseCompany"
                >
                  Company
                </button>
              </h2>
              <div
                id="flush-collapseCompany"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingCompany"
                data-bs-parent="#footerMobile"
              >
                <div class="accordion-body footer">
                  <ul>
                    <li>
                      <a href="https://www.taranawireless.com" target="_blank"
                        >Corporate Website</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.taranawireless.com/blog/"
                        target="_blank"
                        >Blog</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.taranawireless.com/impact/#case-studies"
                        target="_blank"
                        >Case Studies</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://www.taranawireless.com/careers/"
                        target="_blank"
                        >Careers</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingQuicklinks">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseQuicklinks"
                  aria-expanded="false"
                  aria-controls="flush-collapseQuicklinks"
                >
                  Quicklinks
                </button>
              </h2>
              <div
                id="flush-collapseQuicklinks"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingQuicklinks"
                data-bs-parent="#footerMobile"
              >
                <div class="accordion-body footer">
                  <ul>
                    <li>
                      <a
                        href="https://portal.cloud.taranawireless.com/operator-portal"
                        target="_blank"
                        >Tarana Cloud Suite Login</a
                      >
                    </li>
                    <li>
                      <a href="https://learn.taranawireless.com" target="_blank"
                        >Tarana Academy Online</a
                      >
                    </li>
                    <!--<li><a href="#">Partner Login</a></li>-->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="footer">
            <router-link to="/home" class="navbar-brand me-0">
              <img
                src="../../assets/images/logo-black.png"
                width="185"
                alt="logo"
              />
            </router-link>
          </div>
        </div>
        <!--- END COL -->
      </div>
    </div>
    <!--- END CONTAINER -->
    <hr />
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12 footer_bottom">
          <ul>
            <li>
              <a
                href="https://www.taranawireless.com/legal/#privacy-policy"
                target="_blank"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                href="https://www.taranawireless.com/legal/#terms-and-conditions"
                target="_blank"
                >Terms and Conditions</a
              >
            </li>
            <li>
              <a href="https://www.taranawireless.com/legal/" target="_blank"
                >Legal</a
              >
            </li>
          </ul>
        </div>
        <!--- END COL -->
      </div>
    </div>
  </footer>
  <footer
    v-if="['login', 'register'].includes(currentPage)"
    class="login_footer"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12 footer_bottom">
          <ul>
            <li>
              <a
                href="https://www.taranawireless.com/legal/#privacy-policy"
                target="_blank"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                href="https://www.taranawireless.com/legal/#terms-and-conditions"
                target="_blank"
                >Terms and Conditions</a
              >
            </li>
            <li>
              <a href="https://www.taranawireless.com/legal/" target="_blank"
                >Legal</a
              >
            </li>
          </ul>
        </div>
        <!--- END COL -->
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentPage() {
      let page_name = "";
      if (this.$route.params.page_name) {
        page_name = this.$route.path.split("/")[1];
      } else {
        page_name = this.$route.path.replace("/", "");
      }
      return page_name;
    },
  },
};
</script>
