<template>
  <div class="platformbg" v-for="(platform, index) in platformData">
    <div class="container">
      <div class="row paddtop">
        <div class="col-sm-6 mb-3">
          <div class="img-enlarge">
            <img
              :src="`${baseUrl}/storage/${platform.file_path}`"
              :alt="platform.file_name"
              v-if="platform.file_name"
              class="img-fluid centered-img"
              @click="enlargeImage(index)"
            />
            <button class="enlarge-button" @click="enlargeImage(index)">
              <i class="fa fa-expand"></i> Enlarge Image
            </button>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <h2 class="main-title">
            <i class="fa-solid fa-router text-indigo"></i>
            {{ platform.title }}
          </h2>
          <p
            class="sub-title"
            :class="{ 'd-none': checkStringExist(platform) }"
          >
            {{ platform.sub_title }}
          </p>
          <div class="d-none d-md-block">
            <p class="card-text" v-html="platform.description"></p>
          </div>
          <div class="d-block d-md-none">
            <p v-if="!readMore[index]">
              <span
                class="card-text"
                v-html="platform.description.substring(0, 305) + '...'"
              ></span>
            </p>
            <p v-if="readMore[index]">
              <span class="card-text" v-html="platform.description"></span>
            </p>
            <div class="text-center">
              <button
                @click="activateRead(index)"
                v-if="!readMore[index]"
                class="read-button"
              >
                Read More
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </button>
              <button
                @click="activateRead(index)"
                v-if="readMore[index]"
                class="read-button"
              >
                Read Less <i class="fa fa-chevron-up" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog" :id="'popup-' + index" v-if="imgPopup[index]">
      <div class="dialog-content">
        <button @click="closeImage(index)" class="close-icon">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <img
          :src="`${baseUrl}/storage/original/${platform.file_name}`"
          class="img-fluid centered-img"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    platformData: {
      type: Array,
    },
    baseUrl: {
      String,
    },
  },
  data() {
    return {
      readMore: {},
      imgPopup: {},
    };
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        let targetDiv = document.querySelector(".dialog");
        if (targetDiv) {
          this.closeImage(targetDiv.id.split("-")[1]);
        }
      }
    });
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    checkStringExist(platform) {
      const wordsToCheck = ["Sub-header", "Lorem ipsum"];
      if (platform.sub_title) {
        return wordsToCheck.some((word) => platform.sub_title.includes(word));
      }
      return false;
    },
    activateRead(id) {
      this.readMore[id] = !this.readMore[id];
    },
    enlargeImage(id) {
      this.imgPopup[id] = true;
    },
    closeImage(id) {
      this.imgPopup[id] = false;
    },
    handleClickOutside(event) {
      if (event.target.tagName == "DIV") {
        let targetDiv = document.querySelector(".dialog");
        if (targetDiv) {
          this.closeImage(targetDiv.id.split("-")[1]);
        }
      }
    },
  },
};
</script>
